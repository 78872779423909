import React from "react"

import Layout from "../components/layout"
import PageBanner from "../components/pageBanner"
import Article from "../components/article"
import contact from "../support/contact"
import SEO from "../components/seo"

const PoliciesPage = () => (
  <Layout>
    <SEO title="Legal" />
    <PageBanner title="Legal" />
    <Article>
      <div id="content" className="site-content">
        <div className="wrap">
          <div id="primary" className="content-area">
            <main id="main" className="site-main" role="main">
              <article
                id="post-3"
                className="post-3 page type-page status-publish hentry"
              >
                <ul className="policy-index">
                  <li>
                    <a href="#PrivacyPolicy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#TermsAndConditions">
                      Terms and Conditions of Service
                    </a>
                  </li>
                  <li>
                    <a href="#ModernSlavery">
                      Modern Slavery and Human Trafficking Statement
                    </a>
                  </li>
                </ul>
                <h1 className="entry-title" id="PrivacyPolicy">
                  Privacy Policy
                </h1>
                <div className="entry-content">
                  <br />
                  <h5>Privacy Statement</h5>
                  <p>
                    All personal information collected by Headmark Consulting
                    Pty Ltd &nbsp;is protected by the Privacy Act 1988. Further
                    information about the Privacy Act is available on the Office
                    of the Australian Information Commissioner’s website.
                  </p>
                  <p>
                    This Privacy Policy explains Headmark Consulting Pty
                    Ltd’s&nbsp;commitment to protecting the privacy of personal
                    information. It applies to personal information collected by
                    Headmark Consulting Pty Ltd.
                  </p>
                  <br />
                  <p>
                    <h4>
                      Headmark Consulting Pty Ltd’s Complete Privacy Policy.
                    </h4>
                    <br />
                    <h5>Information we collect and how it is used.</h5>
                    <br />
                    Headmark Consulting Pty Ltd achieves its overall objective
                    of quality employment outcomes for Australian industry
                    through:
                  </p>
                  <p>
                    – Effective working relationships with individuals,
                    employment service providers, industry, community groups and
                    other stakeholders
                  </p>
                  <p>
                    – auditing, research and analysis in order to maintain,
                    protect and improve its services
                  </p>
                  <p>
                    Headmark Consulting Pty Ltd collects personal information in
                    order to properly and efficiently carry out its
                    functions.&nbsp;Headmark Consulting Pty Ltd only uses
                    personal information for the purpose(s) for which it was
                    given to Headmark Consulting Pty Ltd and for directly
                    related purposes (unless otherwise required by or authorised
                    by law) or as consented to by the individual concerned.
                  </p>
                  <br />
                  <h5>Communication – emails and electronic forms</h5>
                  <p>
                    Headmark Consulting Pty Ltd’s servers and databases may
                    record your email address if you send Headmark Consulting
                    Pty Ltd a message online. This information may be used to
                    respond to your message, to contact you for the purposes as
                    set out above under the heading Information Headmark
                    Consulting Pty Ltd collects and how it uses it or, where you
                    have provided it to Headmark Consulting Pty Ltd for the
                    purpose of subscribing, to add you to the appropriate
                    subscription service(s). However, your email address will
                    not be added to any third party mailing list.
                  </p>
                  <p>
                    Where you choose to send Headmark Consulting Pty Ltd a
                    completed electronic form that includes your personal
                    details, Headmark Consulting Pty Ltd collects personally
                    identifiable information such as name, address and email
                    address.
                  </p>
                  <p>
                    The information collected by email or electronic forms will
                    not be disclosed unless one of the circumstances described
                    below under the heading Information Sharing has been
                    satisfied.
                  </p>
                  <br />
                  <h5>Log information (browsing)</h5>
                  <p>
                    When you use Headmark Consulting Pty Ltd’s online services,
                    its servers automatically record information that your
                    browser sends whenever you visit a website. These server
                    logs may include information such as your server address,
                    your top level domain name (for example, .com, .gov, .au,
                    .uk, etc), the date and time of visit to the site, the pages
                    accessed and documents viewed, the previous sites visited,
                    the browser type, the browser language, and one or more
                    cookies that may uniquely identify your browser.
                  </p>
                  <br />
                  <h5>Website Analytics</h5>
                  <strong>- Cookies</strong>
                  <p>
                    Headmark Consulting Pty Ltd’s websites use cookies. A cookie
                    is a small file that our site may place on your computer to
                    remember your preferences. It is information that your web
                    browser sends back to Headmark Consulting Pty Ltd’s website
                    server whenever you visit it again.&nbsp; It does not store
                    any personal information.
                  </p>
                  <p>
                    You may refuse the use of cookies by selecting the
                    appropriate settings on your browser, however if you do
                    this, you may not be able to use the full functionality of
                    our websites.
                  </p>
                  <br />
                  <strong>– Google Analytics</strong>
                  <p>
                    Headmark Consulting Pty Ltd uses Google Analytics to collect
                    information about visitors to its website. It anonymously
                    tracks how visitors interact with this website, including
                    how they have accessed the site (for example from a search
                    engine, a link, an advertisement) and what they did on the
                    site. Google Analytics does not identify individual users or
                    associate your IP address with any other data held by
                    Google.
                  </p>
                  <p>
                    Headmark Consulting Pty Ltd uses reports provided by Google
                    Analytics to help us understand website traffic and webpage
                    usage. Headmark Consulting Pty Ltd may also use Google
                    Analytics Demographics and Interest Reporting to help us
                    understand more about visitors to the site including Age,
                    Gender, and Interest data.
                  </p>
                  <p>
                    You can opt out of Google Analytics and limit the tracking
                    applied to your browsing behaviour by third parties by:
                  </p>
                  <p>
                    disabling or refusing cookies;
                    <br />
                    disabling JavaScript;
                    <br />
                    Use the opt-out service provided by Google;
                    <br />
                    Learn more about privacy and online security at the{" "}
                    <a
                      href="https://www.staysmartonline.gov.au/"
                      target="_blank"
                      rel="nofollow"
                    >
                      Stay Smart Online website
                    </a>
                    .
                  </p>
                  <br />
                  <strong>- Social Media</strong>
                  <p>
                    Headmark Consulting Pty Ltd also uses interfaces with social
                    media sites such as Facebook, LinkedIn, Twitter and others
                    and anonymously tracks traffic generated from advertising
                    and marketing on social media sites.
                  </p>
                  <p>
                    Headmark Consulting Pty Ltd may use anonymised analytics
                    information from these sites to measure the effectiveness of
                    our content.
                  </p>
                  <p>
                    If you choose to “like” or “share” information from this
                    website through these services, you should review the
                    privacy policy of that service. If you are a member of a
                    social media site, they may allow the social media site to
                    connect your visits to this site with other Personal
                    Information.
                  </p>
                  <br />
                  <h5>
                    Access and alteration of records containing personal
                    information
                  </h5>
                  <p>
                    When you provide personal information in relation to
                    Headmark Consulting Pty Ltd’s services, Headmark Consulting
                    Pty Ltd will allow you access to your personal information
                    and will correct your personal information if it is
                    inaccurate (subject to restrictions on such access or
                    alteration of records under the applicable provisions of any
                    law of the Commonwealth).
                  </p>
                  <br />
                  <h5>Information sharing</h5>
                  <p>
                    Headmark Consulting Pty Ltd only discloses personal
                    information to third parties in the following circumstances:
                  </p>
                  <p>
                    – where you are reasonably likely to have been aware, or
                    made aware by way of a privacy notice, that your personal
                    information is usually passed to the relevant parties
                    <br />
                    – where you have consented to the disclosure of your
                    personal information
                    <br />
                    – where we believe on reasonable grounds that the disclosure
                    is necessary to prevent or lessen a serious and imminent
                    threat to your life or health or that of another person
                    <br />
                    – where the disclosure is required or authorised by or under
                    law
                    <br />– where the disclosure is reasonably necessary for the
                    enforcement of criminal law or a law imposing a pecuniary
                    penalty, or for the protection of public revenue.
                  </p>
                  <br />
                  <h5>Information security</h5>
                  <p>
                    Headmark Consulting Pty Ltd takes all reasonable steps:
                    <br />
                    – to protect the personal information held in its possession
                    against loss, unauthorised access, use, modification,
                    disclosure or misuse
                    <br />– to ensure that, where it has given personal
                    information to a contractor (that carries out a service for
                    Headmark Consulting Pty Ltd), the contractor complies with
                    the Australian Privacy Principles in the Privacy Act.
                  </p>
                  <br />
                  <h5>Data integrity</h5>
                  <p>
                    Headmark Consulting Pty Ltd takes reasonable steps to make
                    sure that the personal information it collects and stores is
                    accurate, relevant, up-to-date, complete and not misleading.
                  </p>
                  <br />
                  <h5>Changes to this policy</h5>
                  <p>
                    Please note that this Privacy Policy will be regularly
                    reviewed and may change from time to time.
                  </p>
                  <p>
                    Headmark Consulting Pty Ltd
                    <br />
                    Tel – {contact.phoneNumber}
                    <br />
                    www.headmarkconsulting.com
                  </p>
                </div>
                <div id="TermsAndConditions" style={{ paddingTop: "50px" }}>
                  <h1>Terms and Conditions of Service</h1>
                  <p>
                    Please read this agreement carefully before accessing or
                    using the information and services available through the
                    headmarkconsulting.com website (“Site”), operated by
                    Headmark Consulting PTY LTD (“Headmark Consulting”,
                    “Headmark”, ”us”, "we", “our"). By accessing or using the
                    Site, you agree to be bound by the terms and conditions
                    below. Headmark Consulting reserves the right to amend, add
                    to, delete or revise these Terms and Conditions and the
                    Privacy Policy at any time without prior notice, and such
                    modifications shall be effective immediately upon posting
                    the modified agreements on the Site.
                  </p>
                  <p>
                    These Terms and Conditions were last updated in June 2020.
                  </p>
                  <br />
                  <h5>Privacy Policy</h5>
                  <p>
                    Access to and use of the contents and services provided on
                    the Site shall be subject to the{" "}
                    <a href="#PrivacyPolicy">Privacy Policy</a> and these Terms.
                    If you do not accept either or both of the Privacy Policy
                    and/or the Terms and Conditions, do not access, view,
                    download or otherwise use any services offered by Headmark
                    Consulting via the Site. By your continued use of the Site,
                    you acknowledge that you have read and understood the
                    Privacy Policy and the Terms and Conditions and that you
                    agree to be bound by all of its provisions.
                  </p>
                  <br />
                  <h5>Copyright</h5>
                  <p>
                    Copyright in the material and trademarks on this Site are
                    the property of Headmark Consulting PTY LTD ABN 24 627 519
                    493 unless otherwise indicated and protected by
                    international copyright laws. By using this Site you agree
                    not to infringe any intellectual property rights owned by
                    Headmark Consulting.
                  </p>
                  <br />
                  <h5>Information for personal, non-commercial use only</h5>
                  <p>
                    You agree that information contained on this Site is for
                    personal use only and may not be sold, redistributed or used
                    for any commercial purpose.
                  </p>
                  <p>
                    You may download material from this Site for your personal,
                    non-commercial use only, provided you keep intact all
                    copyright and other proprietary notices.
                  </p>
                  <p>
                    You may not modify, copy, reproduce, republish, upload,
                    post, transmit or distribute in any way any material from
                    this Site, including code and software. You must not use
                    this Site for any purpose that is unlawful or prohibited by
                    these Terms.
                  </p>
                  <p>
                    You may not use data mining, robots, screen scraping, or
                    similar data gathering and extraction tools on this Site for
                    establishing, maintaining, advancing or reproducing
                    information contained on our Site on your own website or in
                    any other publication.
                  </p>
                  <p>
                    You acknowledge and agree that information published by the
                    Site is intended to provide general information in summary
                    form on market insights, legal and other issues. Headmark
                    Consulting does not warrant the accuracy or completeness of
                    such information. It is solely your responsibility to
                    evaluate the accuracy, completeness and usefulness of all
                    opinions, advice, services, merchandise and other
                    information provided through the Site or on the Internet
                    generally. In no event will Headmark Consulting be liable to
                    you or anyone else for any decision made or action taken by
                    you or anyone else in reliance upon any information
                    contained on or omitted from the Site. 
                  </p>
                  <br />
                  <h5>Disclaimer of warranties</h5>
                  <p>
                    Headmark Consulting is responsible for maintaining our
                    platform and all headmarkconsulting.com publications.
                    Headmark Consulting make no warranty as to the accuracy or
                    reliability of the information contained therein (including,
                    but not limited to, any content or information generated on
                    our platform by or on behalf of any Third Party Content on
                    our website) and Headmark and its related entities,
                    directors, officers and agents disclaim all liability and
                    responsibility for any direct or indirect loss or damage
                    which may be suffered by any recipient through relying on
                    anything contained in or omitted from our platform or
                    publications. No guarantees of job vacancy
                  </p>
                  <p>
                    Headmark Consulting gives no guarantee to you of the
                    continued availability of any particular job advertised on
                    the Site and will not be liable to you should an advertiser
                    have filled the vacancy at any time prior to removal of the
                    advertisement from the Site. Whilst we take efforts to
                    ensure that jobs advertised are for actual job vacancies, it
                    gives no guarantee to you that every job advertisement
                    represents an actual job vacancy.
                  </p>
                  <br />
                  <h5>Limitation of liability</h5>
                  <p>
                    TO THE EXTENT PERMITTED BY APPLICABLE LAW AND TO THE EXTENT
                    THAT HEADMARK CONSULTING IS OTHERWISE FOUND RESPONSIBLE FOR
                    ANY DAMAGES, HEADMARK CONSULTING IS RESPONSIBLE FOR ACTUAL
                    DAMAGES ONLY.  TO THE EXTENT PERMITTED BY LAW, IN NO EVENT
                    SHALL HEADMARK CONSULTING, ITS AFFILIATES, ITS SUPPLIERS OR
                    ANY THIRD PARTIES MENTIONED AT THE WEBSITE BE LIABLE FOR ANY
                    INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE AND CONSEQUENTIAL
                    DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA
                    OR BUSINESS INTERRUPTION RESULTING FROM THE USE OF OR
                    INABILITY TO USE THE SITE, INFORMATION, SERVICES OR THE
                    CONTENT. NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN,
                    THE AGGREGATE LIABILITY OF HEADMARK CONSULTING PTY LTD AND
                    ITS RELATED ENTITIES TO YOU FOR ANY CAUSE OF ACTION
                    WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                    ALL TIMES BE LIMITED TO AUD100.00
                  </p>
                  <br />
                  <h5>Jurisdiction</h5>
                  <p>
                    Your use of our platform, these terms, our Privacy
                    Policy and all of our legal terms and notices will be
                    governed by and construed in accordance with the laws of
                    Queensland, Australia and by using our platform you
                    irrevocably and unconditionally submit to the jurisdiction
                    of the courts of that State.
                  </p>
                </div>
                <div>
                  <h1
                    className="entry-title"
                    id="ModernSlavery"
                    style={{ paddingTop: "50px" }}
                  >
                    Modern Slavery and Human
                    <br className="d-none d-md-block" /> Trafficking Statement
                  </h1>
                  <br />
                  <h5>Introduction</h5>
                  <p>
                    <span>
                      Modern slavery is a heinous crime and a morally
                      reprehensible act that deprives a person's liberty and
                      dignity for another person's gain. It is a real problem
                      for millions of people around the world, including many in
                      developed countries, who are being kept and exploited in
                      various forms of slavery. Every company is at risk of
                      being involved in this crime through its own operations
                      and its supply chain.
                    </span>
                  </p>
                  <p>
                    <span>
                      At Headmark Consulting Pty Ltd, we have a zero-tolerance
                      approach to modern slavery and are fully committed to
                      preventing slavery and human trafficking in our operation
                      and supply chain. We have taken concrete steps to tackle
                      modern slavery, as outlined in our statement. This
                      statement sets out the actions that we have taken to
                      understand all potential modern slavery risks related to
                      our business, and to implement steps to prevent slavery
                      and human trafficking during the financial year 2019/2020.
                    </span>
                  </p>
                  <br />
                  <h5>Our business and supply chains</h5>

                  <p>
                    <span>
                      Headmark Consulting is a specialised consulting
                      company primarily focused on providing Subject Matter
                      Experts with Information Domain expertise to our
                      customers. Our team also provide more broad Defence and
                      Industry experience and skill in Business and Operations
                      Analysis.
                    </span>
                  </p>
                  <p>
                    <span>
                      Our Information Domain expertise extends across
                      Intelligence, Surveillance and Reconnaissance,
                      Communications, Command and Control, Information Systems
                      and Electronic Warfare in the Joint, Maritime, Land and
                      Air domains.
                    </span>
                  </p>
                  <p>
                    <span>
                      Our Consulting and Analyst expertise expands across all
                      Domains and Warfare disciplines, as well Logistics and
                      Engineering and Personnel.
                    </span>
                  </p>
                  <p>
                    <span>
                      We provide expertise in Policy, Procedure and Governance,
                      Capability Life Cycle, Integrated Logistics Support and
                      experience in Capability Needs, Effects and Outputs
                      generation and assessment.
                    </span>
                  </p>
                  <p>
                    <span>
                      Headmark Consulting is Headquartered in Brisbane,
                      Queensland Australia with a Virtual Presence based in
                      North London, United Kingdom. Our Staff work across
                      Australia delivering for our customers.
                    </span>
                  </p>
                  <p>
                    <span>
                      We establish a relationship of trust and integrity with
                      all our suppliers, which is built upon mutually beneficial
                      factors. Our supplier selection and on-boarding procedure
                      includes due diligence of the supplier's reputation,
                      respect for the law, compliance with health, safety and
                      environmental standards, and references.
                    </span>
                  </p>
                  <p>
                    <span>
                      We haven't been made aware of any allegations of human
                      trafficking/slavery activities against any of our
                      suppliers, but if we were, then we would act immediately
                      against the supplier and report it to the authorities.
                    </span>
                  </p>
                  <br />
                  <h5>Risk assessment</h5>
                  <p>
                    <span>
                      In the past year, we conducted a risk assessment of our
                      supply chain by taking into account:{" "}
                    </span>
                  </p>
                  <ul>
                    <li>
                      The risk profile of individual countries based on the
                      Global Slavery Index
                    </li>
                    <li>The business services rendered by the suppliers</li>
                    <li>The presence of vulnerable demographic groups</li>
                    <li>
                      A news analysis and the insights of labour and human
                      rights groups
                    </li>
                  </ul>
                  <p>
                    This assessment will determine our response and the risk
                    controls that we implement.
                  </p>
                  <br />
                  <h5>Policies</h5>
                  <p>
                    Headmark Consulting operates the following policies for
                    identifying and preventing slavery and human trafficking in
                    our operations:
                  </p>
                  <ul>
                    <li>
                      Whistleblowing Policy - we encourage all employees,
                      customers and suppliers to report any suspicion of slavery
                      or human trafficking without fear of retaliation.
                    </li>
                    <li>
                      Code of Conduct - our code encourages employees to do the
                      right thing by clearly stating the actions and behaviour
                      expected of them when representing the business. We strive
                      to maintain the highest standards of employee conduct and
                      ethical behaviour when operating abroad and managing our
                      supply chain.
                    </li>
                    <li>
                      Purchasing Code - we have updated our Purchasing Code and
                      supplier contracts to make explicit reference to slavery
                      and human trafficking.
                    </li>
                  </ul>
                  <br />
                  <h5>Supplier due diligence </h5>
                  <p>
                    Headmark Consulting conducts due diligence on all new
                    suppliers during on-boarding and on existing suppliers at
                    regular intervals. This includes:
                  </p>
                  <ul>
                    <li>
                      Assessing risks in the provision of particular services
                    </li>
                    <li>
                      Auditing the suppliers, and their health and safety
                      standards, labour relations and employee contracts
                    </li>
                    <li>
                      Requiring improvements to substandard employment practices
                    </li>
                    <li>
                      Sanctioning suppliers that fail to improve their
                      performance in line with our requirements
                    </li>
                  </ul>
                  <br />
                  <h5>We require all suppliers to attest that:</h5>
                  <ul>
                    <li>
                      They don't use any form of forced, compulsory or slave
                      labour
                    </li>
                    <li>
                      Their employees work voluntarily and are entitled to leave
                      work
                    </li>
                    <li>
                      They provide each employee with an employment contract
                      that contains a reasonable notice period for terminating
                      their employment
                    </li>
                    <li>
                      They don't require employees to post a deposit/bond and
                      don't withhold their salaries for any reasons
                    </li>
                    <li>
                      They don't require employees to surrender their passports
                      or work permits as a condition of employment
                    </li>
                  </ul>
                  <br />
                  <h5>Awareness </h5>
                  <p>
                    Headmark Consulting has raised awareness of modern slavery
                    issues by regular communication with staff (permanent and
                    contract) sending emails that are focused specifically on
                    modern slavery to all our staff, which explains:
                  </p>
                  <ul>
                    <li>Our commitment in the fight against modern slavery</li>
                    <li>
                      Red flags for potential cases of slavery or human
                      trafficking
                    </li>
                    <li>
                      How employees should report suspicions of modern slavery
                    </li>
                  </ul>
                  <br />
                  <h5>Training </h5>
                  <p>
                    In addition to the awareness programme, Headmark Consulting
                    will be rolling out a fresh learning course to all employees
                    and supplier contacts, which covers:
                  </p>
                  <ul>
                    <li>
                      Various forms of modern slavery in which people can be
                      held and exploited
                    </li>
                    <li>
                      The size of the problem and the risk to our organisation
                    </li>
                    <li>
                      How employees can identify the signs of slavery and human
                      trafficking, including unrealistically low prices
                    </li>
                    <li>
                      How employees should respond if they suspect slavery or
                      human trafficking
                    </li>
                    <li>
                      How suppliers can escalate potential slavery or human
                      trafficking issues to the relevant people within their own
                      organisation
                    </li>
                    <li>
                      What external help is available for the victims of slavery
                    </li>
                    <li>
                      What terms and guidance should be provided to suppliers in
                      relation to slavery policies and controls
                    </li>
                    <li>
                      What steps Headmark Consulting will take if a supplier
                      fails to implement anti-slavery policies or controls
                    </li>
                    <li>
                      An attestation from employees that they will abide by
                      Headmark Consulting’s anti-slavery policy
                    </li>
                  </ul>
                  <br />
                  <h5>Measuring how we're performing</h5>
                  <p>
                    Headmark Consulting has defined a set of key performance
                    indicators and controls to combat modern slavery and human
                    trafficking in our organisation and supply chain. These
                    include:
                  </p>
                  <ul>
                    <li>
                      How many employees have completed mandatory training?
                    </li>
                    <li>
                      How many suppliers have filled out our ethics
                      questionnaire?
                    </li>
                    <li>
                      How many suppliers have rolled out an awareness and
                      training programme that is equivalent to ours?
                    </li>
                    <li>
                      How many reports have been made by our employees that
                      indicate their awareness of and sensitivity to ethical
                      issues?
                    </li>
                    <li>
                      What are the findings of our cross-functional Human Rights
                      team, which reviews how we are addressing modern slavery
                      and human trafficking?
                    </li>
                  </ul>
                  <p>
                    We are proud of our progress to date, but we recognise there
                    is more to do. We are committed to the ongoing delivery of
                    our initiatives and partnering with our stakeholders to
                    eradicate modern slavery
                  </p>
                  <p>
                    This statement covers 1 July 2019 to 30 June 2020 and has
                    been approved by the board of Headmark Consulting at the
                    board meeting on 25 August 2019.
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </Article>
  </Layout>
)

export default PoliciesPage
